export class colElement {
  constructor(
    public id: number,
    public colid: number,
    public elementType: string,
    public elementTitle: string,
    public mandatory?: boolean,
    public validation?: string,
    public name?: string,
    public placeholder?: string,
    public options?: Array<string>,
    public valor?: string
  ) {}
}
