import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private _http: HttpClient) {}

  getJson() {
    return this._http.get("/api/html");
  }

  getTokenGoogle() {
    return this._http.get("https://api.translateyourworld.com/api/Session", {
      headers: new HttpHeaders().append("Authorization", "Basic 111"),
    });
  }

  getTokenTTS() {
    return this._http.get(
      "https://api.worldwidetechconnections.com/api/Session",
      {
        headers: new HttpHeaders().append(
          "Authorization",
          "Basic aa8b6f96-e2c6-4516-a4b3-ef92b1bc1ed9"
        ),
      }
    );
  }

  translatev2(audio, sourceLanguageCode, targetLanguageCode) {
    const url = `https://core.worldwidetechconnections.com/services/sts/${sourceLanguageCode}/${targetLanguageCode}`;
    const body = audio;
    return this._http.post(url, body).pipe(map((data) => data));
  }

  getTraducedJSON(
    token: string,
    language: string,
    targetLang: string,
    text: string
  ) {
    let url = `https://api.translateyourworld.com/api/Translation?Text=${text}&sourceLanguage=${language}&targetLanguage=${targetLang}&vendor=google&token=${token}`;

    return this._http.get(url, {
      headers: new HttpHeaders().append("Authorization", "Basic 111"),
    });
  }

  getTTS(token: string, language: string, text: string) {
    let url = `https://api.worldwidetechconnections.com/api/TextToSpeech?text=${text}&sourcelanguage=${language}&vendor=ReadSpeaker&token=${token}&voice=${language}`;

    if (language === "zh-CN")
      url = `https://api.worldwidetechconnections.com/api/TextToSpeech?text=${text}&sourcelanguage=${language}&vendor=ReadSpeaker&token=${token}&voice=hui`;

    return this._http.get(url, {
      headers: new HttpHeaders().append(
        "Authorization",
        "Basic aa8b6f96-e2c6-4516-a4b3-ef92b1bc1ed9"
      ),
      responseType: "arraybuffer",
    });
  }

  getTTSwwtc(language: string, text: string) {
    let url = `https://core.worldwidetechconnections.com/services/tts/${language}/${language}?text=${text}`;
    return this._http.post(url, null);
  }

  detectlanguage(audio: any, array?: any) {
    var TOKEN = "UcvpcISVqEK5WuC3o5X80A";
    var API_BASE_URL = "https://api.worldwidetechconnections.com/api/";
    var vendor = "google";

    console.log(array);

    var params = JSON.stringify(array);

    // var params = arrayLang.toString();

    if (array) {
      var API_BASE_URL2 = `https://rb7aid96ng.execute-api.us-east-1.amazonaws.com/prod/language-recognition?languages=${params}`;
    } else {
      var API_BASE_URL2 = `https://rb7aid96ng.execute-api.us-east-1.amazonaws.com/prod/language-recognition`;
    }
    var form = new FormData();
    form.append("data", audio, "ORIGINAL.wav");

    // var url =
    //   API_BASE_URL +
    //   "SpeechToText?token=" +
    //   TOKEN +
    //   "&vendor=" +
    //   vendor +
    //   "&sourceLanguage=*";

    var url = API_BASE_URL2;

    return this._http.post(url, audio, {
      headers: new HttpHeaders({
        Authorization: "Basic 1234567890",
      }),
    });
  }
}
