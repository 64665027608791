import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { NgxBootstrapSliderModule } from "ngx-bootstrap-slider";
import { NewFormModule } from "./new-form/new-form.module";
import { UserFormsModule } from "./user-forms/user-forms.module";
import { FormsModule } from "@angular/forms";
import { DynamicFormService } from "./services/dynamic-form.service";
import { StatsModule } from "./stats/stats.module";
import { APP_BASE_HREF } from "@angular/common";
import { environment } from "src/environments/environment";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NewFormModule,
    UserFormsModule,
    FormsModule,
    StatsModule,
  ],
  providers: [
    DynamicFormService,
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
