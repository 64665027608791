import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter
} from "@angular/core";
import { DndDropEvent, DropEffect } from "ngx-drag-drop";
import { field, value } from "../../global.model";
import { ActivatedRoute } from "@angular/router";
import swal from "sweetalert2";
import { DynamicFormService } from "src/app/services/dynamic-form.service";

import { polyfill } from "mobile-drag-drop";
// optional import of scroll behaviour
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";

polyfill({
  // use this to make use of the scroll behaviour
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
});

// workaround to make scroll prevent work in iOS Safari > 10
try {
  window.addEventListener("touchmove", function() {}, { passive: false });
} catch (e) {}

@Component({
  selector: "app-edit-app",
  templateUrl: "./edit-app.component.html",
  styleUrls: ["./edit-app.component.scss"]
})
export class EditAppComponent implements OnInit {
  @Input() row: number;
  @Input() col: number;
  @Input() width: number;
  @Input() arrayElements: any;
  @Input() onlyCanvas: boolean;
  @Input() onlyElements: boolean;
  @Output() elements: EventEmitter<any> = new EventEmitter();
  @Output() firtsElement: EventEmitter<any> = new EventEmitter();
  public band = true;
  public value: value = {
    label: "",
    value: ""
  };
  success = false;
  fieldModels: Array<field> = [
    {
      type: "text",
      icon: "fa-font",
      label: "Input",
      required: false,
      description: "Enter your name",
      placeholder: "Enter your name",
      className: "form-control",
      subtype: "text",
      regex: "",
      handle: true
    },
    {
      type: "email",
      icon: "fa-envelope",
      required: false,
      label: "Email",
      description: "Enter your email",
      placeholder: "Enter your email",
      className: "form-control",
      subtype: "text",
      regex: "^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$",
      errorText: "Please enter a valid email",
      handle: true
    },
    {
      type: "phone",
      icon: "fa-phone",
      label: "Phone",
      required: false,
      description: "Enter your phone",
      placeholder: "Enter your phone",
      className: "form-control",
      subtype: "text",
      regex: "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$",
      errorText: "Please enter a valid phone number",
      handle: true
    },
    {
      type: "number",
      label: "Number",
      required: false,
      icon: "fa-hashtag",
      description: "Age",
      placeholder: "Enter your age",
      className: "form-control",
      value: "20",
      min: 12,
      max: 90
    },
    {
      type: "date",
      required: false,
      icon: "fa-calendar",
      label: "Date",
      placeholder: "Date",
      className: "form-control"
    },

    /* icon: "fa-calendar", */
    {
      type: "datetime-local",
      required: false,
      icon: "fa-calendar",
      label: "Date Time",
      placeholder: "Date Time",
      className: "form-control"
    },
    {
      type: "textarea",
      required: false,
      icon: "fa-text-width",
      label: "Text Area"
    },
    {
      type: "paragraph",
      required: false,
      icon: "fa-paragraph",
      label: "Paragraph",
      placeholder: "Type your text to display here only"
    },
    {
      type: "checkbox",
      required: false,
      label: "Checkbox",
      icon: "fas fa-check-circle",
      description: "Checkbox",
      inline: true,
      values: [
        {
          label: "Option 1",
          value: "option-1"
        },
        {
          label: "Option 2",
          value: "option-2"
        }
      ]
    },
    {
      type: "radio",
      required: false,
      icon: "far fa-dot-circle",
      label: "Radio",
      description: "Radio boxes",
      values: [
        {
          label: "Option 1",
          value: "option-1"
        },
        {
          label: "Option 2",
          value: "option-2"
        }
      ]
    },
    {
      type: "autocomplete",
      required: false,
      icon: "fas fa-list-ol",
      label: "Select",
      description: "Select",
      placeholder: "Select",
      className: "form-control",
      values: [
        {
          label: "Option 1",
          value: "option-1"
        },
        {
          label: "Option 2",
          value: "option-2"
        },
        {
          label: "Option 3",
          value: "option-3"
        }
      ]
    }
    // {
    //   type: "file",
    //   required: false,
    //   icon: "fa-file",
    //   label: "File Upload",
    //   className: "form-control",
    //   subtype: "file"
    // }

    /* {
      type: "button",
      icon: "fa-paper-plane",
      subtype: "submit",
      label: "Submit"
    } */
  ];

  modelFields: Array<field> = [];
  model: any = {
    row: this.row,
    col: this.col,
    width: this.width,
    name: "App name...",
    description: "App Description...",
    theme: {
      bgColor: "ffffff",
      textColor: "555555",
      bannerImage: ""
    },
    attributes: this.modelFields
  };

  report = false;
  reports: any = [];

  constructor(
    private route: ActivatedRoute,
    private _dynamicFormService: DynamicFormService
  ) {}

  ngOnInit() {
    if (this.arrayElements) {
      this.model.attributes = this.arrayElements;
      let aux = {};
      let temp = [];
      for (let index = 0; index < this.arrayElements.length; index++) {
        if (this.arrayElements[index].options) {
          aux = {
            id: this.arrayElements[index].id,
            colid: this.arrayElements[index].colid,
            type: this.arrayElements[index].elementType,
            label: this.arrayElements[index].elementTitle,
            required: this.arrayElements[index].mandatory,
            validation: "",
            name: this.arrayElements[index].name,
            values: this.arrayElements[index].options
          };
        } else {
          aux = {
            id: this.arrayElements[index].id,
            colid: this.arrayElements[index].colid,
            type: this.arrayElements[index].elementType,
            label: this.arrayElements[index].elementTitle,
            required: this.arrayElements[index].mandatory,
            validation: "",
            name: this.arrayElements[index].name,
            placeholder: this.arrayElements[index].placeholder
          };
        }
        temp.push(aux);
      }
      this.model.attributes = temp;
    }

    this.model.row = this.row;
    this.model.col = this.col;
    this.model.width = Number(this.width);
  }
  /**
   *
   * @param changes
   * Detects changes to the component and outputs the model of the selected element
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.elements.emit(this.model);
  }

  onDragStart(event: DragEvent) {
    // console.log("drag started", JSON.stringify(event, null, 2));
  }

  onDragEnd(event: DragEvent) {
    // console.log("drag ended", JSON.stringify(event, null, 2));
  }

  onDraggableCopied(event: DragEvent) {
    // console.log("draggable copied", JSON.stringify(event, null, 2));
  }

  onDraggableLinked(event: DragEvent) {
    // console.log("draggable linked", JSON.stringify(event, null, 2));
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDragCanceled(event: DragEvent) {
    // console.log("drag cancelled", JSON.stringify(event, null, 2));
  }

  onDragover(event: DragEvent) {
    // console.log("dragover", JSON.stringify(event, null, 2));
  }
  onDrop(event: DndDropEvent, list?: any[]) {
    if (this._dynamicFormService.firstElement == true) {
      this.firtsElement.emit(this.band);
      this._dynamicFormService.firstElement = false;
    }
    if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {
      if (event.dropEffect === "copy")
        event.data.name = event.data.type + "-" + new Date().getTime();
      let index = event.index;
      if (typeof index === "undefined") {
        index = list.length;
      }
      list.splice(index, 0, event.data);
    }
  }
  addValue(values) {
    values.push(this.value);
    this.value = { label: "", value: "" };
  }

  removeField(i) {
    swal({
      title: "Are you sure?",
      text: "Do you want to remove this field?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00B96F",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove!"
    }).then(result => {
      if (result.value) {
        this.model.attributes.splice(i, 1);
      }
    });
  }

  updateForm() {
    let input = new FormData();
    input.append("id", this.model._id);
    input.append("name", this.model.name);
    input.append("description", this.model.description);
    input.append("bannerImage", this.model.theme.bannerImage);
    input.append("bgColor", this.model.theme.bgColor);
    input.append("textColor", this.model.theme.textColor);
    input.append("attributes", JSON.stringify(this.model.attributes));

    // this.us.putDataApi('/admin/updateForm',input).subscribe(r=>{
    //   console.log(r);
    //   swal('Success','App updated successfully','success');
    // });
  }

  initReport() {
    this.report = true;
    let input = {
      id: this.model._id
    };
    // this.us.getDataApi('/admin/allFilledForms',input).subscribe(r=>{
    //   this.reports = r.data;
    //   console.log('reports',this.reports);
    //   this.reports.map(records=>{
    //     return records.attributes.map(record=>{
    //       if(record.type=='checkbox'){
    //         record.value = record.values.filter(r=>r.selected).map(i=>i.value).join(',');
    //       }
    //     })
    //   });
    // });
  }

  toggleValue(item) {
    item.selected = !item.selected;
  }

  submit() {
    let valid = true;
    let validationArray = JSON.parse(JSON.stringify(this.model.attributes));
    validationArray.reverse().forEach(field => {
      // console.log(field.label + "=>" + field.required + "=>" + field.value);
      if (field.required && !field.value && field.type != "checkbox") {
        swal("Error", "Please enter " + field.label, "error");
        valid = false;
        return false;
      }
      if (field.required && field.regex) {
        let regex = new RegExp(field.regex);
        if (regex.test(field.value) == false) {
          swal("Error", field.errorText, "error");
          valid = false;
          return false;
        }
      }
      if (field.required && field.type == "checkbox") {
        if (field.values.filter(r => r.selected).length == 0) {
          swal("Error", "Please enterrr " + field.label, "error");
          valid = false;
          return false;
        }
      }
    });
    if (!valid) {
      return false;
    }
    // console.log("Save", this.model);
    let input = new FormData();
    input.append("formId", this.model._id);
    input.append("attributes", JSON.stringify(this.model.attributes));
    // this.us.postDataApi('/user/formFill',input).subscribe(r=>{
    //   console.log(r);
    //   swal('Success','You have contact sucessfully','success');
    //   this.success = true;
    // },error=>{
    //   swal('Error',error.message,'error');
    // });
  }
}
