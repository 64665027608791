import { Component } from "@angular/core";
declare let gapi: any;
declare let google: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  developerKey = "AIzaSyDbKTX_9Qq2D1tZzdBUa5nX4BbxxXjeOs0";
  clientId =
    "468524648451-2jdsaq9uhgkjg4idtrdd33ljc7j11ao8.apps.googleusercontent.com";
  scope = [
    "profile",
    "email",
    "https://www.googleapis.com/auth/drive", //insert scope here
  ].join(" ");
  pickerApiLoaded = false;
  oauthToken?: any;

  loadGoogleDrive() {
    gapi.load("client:auth2", { callback: this.onAuthApiLoad.bind(this) });
    gapi.load("picker", { callback: this.onPickerApiLoad.bind(this) });
  }

  onAuthApiLoad() {
    gapi.auth.authorize(
      {
        client_id: this.clientId,
        scope: this.scope,
        immediate: false,
      },
      this.handleAuthResult
    );
  }

  onPickerApiLoad() {
    this.pickerApiLoaded = true;
    this.createPicker();
  }

  createPicker() {
    if (this.pickerApiLoaded && this.oauthToken) {
      let picker = new google.picker.PickerBuilder()
        .addView(google.picker.ViewId.DOCS)
        .setOAuthToken(this.oauthToken)
        .setDeveloperKey(this.developerKey)
        .setCallback(this.pickerCallback.bind(this))
        .build();
      picker.setVisible(true);
    }
  }

  pickerCallback(data: any) {
    let url = "nothing";
    if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
      let doc = data[google.picker.Response.DOCUMENTS][0];
      url = doc[google.picker.Document.URL];
    }
    let message = "You picked: " + url;
    alert(message);
  }

  handleAuthResult(authResult: any) {
    debugger;
    let src;
    if (authResult && !authResult.error) {
      if (authResult.access_token) {
        let view = new google.picker.View(google.picker.ViewId.DOCS);
        view.setMimeTypes("image/png,image/jpeg,image/jpg,video/mp4");
        let pickerBuilder = new google.picker.PickerBuilder();
        let picker = pickerBuilder
          .enableFeature(google.picker.Feature.NAV_HIDDEN)
          .setOAuthToken(authResult.access_token)
          .addView(view)
          .addView(new google.picker.DocsUploadView())
          .setCallback(function (e: any) {
            if (
              e[google.picker.Response.ACTION] == google.picker.Action.PICKED
            ) {
              let doc = e[google.picker.Response.DOCUMENTS][0];
              src = doc[google.picker.Document.URL];
              console.log("Document selected is", doc, "and URL is ", src);
            }
          })
          .build();
        picker.setVisible(true);
      }
    }
  }
}
