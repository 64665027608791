import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { FormStatsComponent } from "./form-stats/form-stats.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  { path: "form-stats", component: FormStatsComponent },
  { path: "admin-dashboard", component: AdminDashboardComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StatsRoutingModule {}
