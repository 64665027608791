import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DynamicFormService } from "src/app/services/dynamic-form.service";
import { AuthService } from "src/app/auth/auth.service";
import { LanguagesService } from "src/app/services/languages.service";
import swal from "sweetalert2";

declare var $: any;

@Component({
  selector: "app-config-form",
  templateUrl: "./config-form.component.html",
  styleUrls: ["./config-form.component.scss"],
})
export class ConfigFormComponent implements OnInit {
  arrayLanguages = [];
  orderedLanguages = true;
  checkAllVal = false;
  canFinish = false;
  isUpdating = false;
  loading = false;
  jsonFinal = {};
  logoURL;
  user;

  constructor(
    private router: Router,
    private _dynamicForm: DynamicFormService,
    private authService: AuthService,
    private _router: Router,
    private languagesService: LanguagesService
  ) {
    this.logoURL = null;
  }

  ngOnInit() {
    this.user = this.authService.getCurrentUser();

    this.languagesService.getLanguagesNew().subscribe(
      (response) => {
        console.log(response);

        for (let i = 0; i < response.length; i++) {
          const element = response[i];

          this.arrayLanguages.push({
            name: element.name,
            id: element.code,
            isActive: false,
            flag: element.flag,
          });
        }
      },
      (error) => {},
      () => {
        this.ordenar();

        console.log(this.arrayLanguages);

        this.isUpdating = this._dynamicForm.isUpdatingForm;

        if (this.isUpdating) {
          let array = this._dynamicForm.editForm.languages;

          for (let i = 0; i < array.length; i++) {
            this.toggleLang(array[i]);
          }
        }
      }
    );

    // this.languagesService.getLanguages(this.authService.getToken()).subscribe(
    //   (response) => {
    //     console.log(response);

    //     for (let i = 0; i < response.data.length; i++) {
    //       const element = response.data[i];

    //       this.arrayLanguages.push({
    //         name: element.title,
    //         id: element.code,
    //         isActive: false,
    //       });
    //     }
    //   },
    //   (error) => {},
    //   () => {
    //     this.ordenar();

    //     this.isUpdating = this._dynamicForm.isUpdatingForm;

    //     if (this.isUpdating) {
    //       let array = this._dynamicForm.editForm.languages;

    //       for (let i = 0; i < array.length; i++) {
    //         this.toggleLang(array[i]);
    //       }
    //     }
    //   }
    // );
  }

  /**
   * Sets the active language as the given by id
   */
  toggleLang(id) {
    let code;

    if (id.code) {
      code = id.code;
    } else {
      code = id;
    }

    var index = this.arrayLanguages.findIndex(function (item, i) {
      return item.id === code;
    });
    this.arrayLanguages[index].isActive = !this.arrayLanguages[index].isActive;

    this.canFinish = false;
    for (let index = 0; index < this.arrayLanguages.length; index++) {
      if (this.arrayLanguages[index].isActive) this.canFinish = true;
    }
  }

  /**
   * Selects all the languages in the grid
   */
  checkAll() {
    for (let index = 0; index < this.arrayLanguages.length; index++) {
      this.arrayLanguages[index].isActive = true;
    }
    this.canFinish = true;
  }

  /**
   * Unselects all the languages in the grid
   */
  uncheckAll() {
    for (let index = 0; index < this.arrayLanguages.length; index++) {
      this.arrayLanguages[index].isActive = false;
    }
    this.canFinish = false;
  }

  /**
   * Enables or disables drag and drop
   */
  switchDragNdrop() {
    if (!this.orderedLanguages) {
      this.enableDragAndDrop();
    } else {
      this.disableDragAndDrop();
    }
  }

  /**
   * Switches between select all or unselect all the languages
   */
  toggleCheck() {
    if (this.checkAllVal) this.checkAll();
    else this.uncheckAll();
  }

  /**
   *Enables drag and drop
   */
  enableDragAndDrop() {
    var arrayLanguages_ = this.arrayLanguages;
    var arraySorted = [];
    $(function () {
      $("#sortable").sortable();
      $("#sortable").sortable("option", "disabled", false);
      $("#sortable").disableSelection();

      var arrayDragNdrop = $("#sortable").sortable("toArray");
      for (let i = 0; i < arrayDragNdrop.length; i++) {
        for (let j = 0; j < arrayLanguages_.length; j++) {
          if (arrayDragNdrop[i] === arrayLanguages_[j].id) {
            arraySorted.push({
              name: arrayLanguages_[j].name,
              id: arrayDragNdrop[i],
              isActive: arrayLanguages_[j].isActive,
              flag: arrayLanguages_[j].flag,
            });
          }
        }
      }
    });

    this.arrayLanguages = arraySorted;
  }

  /**
   *Disables drag and drop
   */
  disableDragAndDrop() {
    var arrayLanguages_ = this.arrayLanguages;
    var arraySorted = [];
    $(function () {
      $("#sortable").sortable("disable");

      var arrayDragNdrop = $("#sortable").sortable("toArray");
      for (let i = 0; i < arrayDragNdrop.length; i++) {
        for (let j = 0; j < arrayLanguages_.length; j++) {
          if (arrayDragNdrop[i] === arrayLanguages_[j].id) {
            arraySorted.push({
              name: arrayLanguages_[j].name,
              id: arrayDragNdrop[i],
              isActive: arrayLanguages_[j].isActive,
              flag: arrayLanguages_[j].flag,
            });
          }
        }
      }
    });
    this.arrayLanguages = arraySorted;
    this.ordenar();
  }

  /**
   *Orders by name the grid of languages
   */
  ordenar() {
    this.arrayLanguages.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  /**
   *Sends the final JSON of the form to API
   */
  finish() {
    this.getCustomLangOrder();

    if (
      this._dynamicForm.jsonTheme.nameTheme == "" ||
      this._dynamicForm.jsonTheme.parent == ""
    ) {
      (this._dynamicForm.jsonTheme.nameTheme = "default"),
        (this._dynamicForm.jsonTheme.parent = "parent"),
        (this._dynamicForm.jsonTheme.email = this.user.email);
      this._dynamicForm.jsonTheme.dataTheme.backColor1 = "#0f243e";
      this._dynamicForm.jsonTheme.dataTheme.backColor2 = "#00b0f0";
      this._dynamicForm.jsonTheme.dataTheme.boxColor = "#00000000";
      this._dynamicForm.jsonTheme.dataTheme.textHeader = "#ffffff";
      this._dynamicForm.jsonTheme.dataTheme.textContent = "#ffffff";
      this._dynamicForm.jsonTheme.dataTheme.playColor = "#ffffff";
    }

    this.loading = true;
    this._dynamicForm.languages = [];
    for (let index = 0; index < this.arrayLanguages.length; index++) {
      if (this.arrayLanguages[index].isActive === true) {
        console.log(this.arrayLanguages[index]);
        this._dynamicForm.languages.push({
          code: this.arrayLanguages[index].id,
          flag: this.arrayLanguages[index].flag,
        });
      }
    }

    if (this._dynamicForm.logoFile) {
      this._dynamicForm
        .saveImg(this.authService.getToken(), this._dynamicForm.logoFile)
        .subscribe(
          (response) => {
            this.logoURL = response;

            this.jsonFinal = {
              title: this._dynamicForm.formName,
              publicTitle: this._dynamicForm.publicTitle,
              instructions: this._dynamicForm.instructions,
              publicTitleCheck: this._dynamicForm.publicTitleCheck,
              instructionsCheck: this._dynamicForm.instructionsCheck,
              languages: this._dynamicForm.languages,
              signature: this._dynamicForm.signature,
              formRows: this._dynamicForm.previewJson,
              logo_url: this.logoURL.logo_url,
              theme: this._dynamicForm.jsonTheme,
              selectedFont: this._dynamicForm.jsonTheme.selectedFont,
              logoAlign: this._dynamicForm.jsonTheme.logoAlign,
              form_ispublic: !this._dynamicForm.isPrivate,
            };

            if (!this.isUpdating) {
              this._dynamicForm
                .setJSON(
                  JSON.stringify(this.jsonFinal),
                  this.authService.getToken()
                )
                .subscribe(
                  (response) => {
                    this._dynamicForm.publicURL =
                      "/user-forms/public-form/" + response.form.public_link;
                    this._dynamicForm.tokenURL = response.form.public_link;
                    this._router.navigate(["/user-forms/dashboard"]);
                  },
                  (error) => {
                    swal(
                      "Ooops",
                      "An error occurred while creating the form",
                      "error"
                    );
                    this._router.navigate(["/user-forms/dashboard"]);
                  }
                );
            } else {
              this._dynamicForm
                .updateJSON(
                  this._dynamicForm.editForm.id,
                  JSON.stringify(this.jsonFinal),
                  this.authService.getToken()
                )
                .subscribe((response) => {
                  this._dynamicForm.publicURL =
                    "/user-forms/public-form/" + response.form.public_link;
                  this._dynamicForm.tokenURL = response.form.public_link;
                  this._router.navigate(["/user-forms/dashboard"]);
                });
            }
          },
          (error) => {
            swal("Ooops", "An error occurred while creating the form", "error");
            this._router.navigate(["/user-forms/dashboard"]);
          },
          () => {}
        );
    } else {
      this.jsonFinal = {
        title: this._dynamicForm.formName,
        publicTitle: this._dynamicForm.publicTitle,
        instructions: this._dynamicForm.instructions,
        publicTitleCheck: this._dynamicForm.publicTitleCheck,
        instructionsCheck: this._dynamicForm.instructionsCheck,
        languages: this._dynamicForm.languages,
        signature: this._dynamicForm.signature,
        formRows: this._dynamicForm.previewJson,
        logo_url: "",
        theme: this._dynamicForm.jsonTheme,
        selectedFont: this._dynamicForm.jsonTheme.selectedFont,
        logoAlign: this._dynamicForm.jsonTheme.logoAlign,
        form_ispublic: !this._dynamicForm.isPrivate,
      };

      //validacion logo despues de editar
      if (this._dynamicForm.editForm) {
        if (this._dynamicForm.editForm.logo_url != "") {
          this.jsonFinal = {
            title: this._dynamicForm.formName,
            publicTitle: this._dynamicForm.publicTitle,
            instructions: this._dynamicForm.instructions,
            publicTitleCheck: this._dynamicForm.publicTitleCheck,
            instructionsCheck: this._dynamicForm.instructionsCheck,
            languages: this._dynamicForm.languages,
            signature: this._dynamicForm.signature,
            formRows: this._dynamicForm.previewJson,
            logo_url: this._dynamicForm.editForm.logo_url,
            theme: this._dynamicForm.jsonTheme,
            selectedFont: this._dynamicForm.jsonTheme.selectedFont,
            logoAlign: this._dynamicForm.jsonTheme.logoAlign,
            form_ispublic: !this._dynamicForm.isPrivate,
          };
        }
      }

      if (!this.isUpdating) {
        this._dynamicForm
          .setJSON(JSON.stringify(this.jsonFinal), this.authService.getToken())
          .subscribe(
            (response) => {
              this._dynamicForm.publicURL =
                "/user-forms/public-form/" + response.form.public_link;
              this._dynamicForm.tokenURL = response.form.public_link;
              this._router.navigate(["/user-forms/dashboard"]);
            },
            (error) => {
              swal(
                "Ooops",
                "An error occurred while updating the form",
                "error"
              );
              this._router.navigate(["/user-forms/dashboard"]);
            }
          );
      } else {
        this._dynamicForm
          .updateJSON(
            this._dynamicForm.editForm.id,
            JSON.stringify(this.jsonFinal),
            this.authService.getToken()
          )
          .subscribe((response) => {
            this._dynamicForm.publicURL =
              "/user-forms/public-form/" + response.form.public_link;
            this._dynamicForm.tokenURL = response.form.public_link;
            this._router.navigate(["/user-forms/dashboard"]);
          });
      }
    }

    console.log(JSON.stringify(this.jsonFinal));
  }

  getCustomLangOrder() {
    var arrayLanguages_ = this.arrayLanguages;
    var arraySorted = [];
    $(function () {
      $("#sortable").sortable();

      var arrayDragNdrop = $("#sortable").sortable("toArray");
      for (let i = 0; i < arrayDragNdrop.length; i++) {
        for (let j = 0; j < arrayLanguages_.length; j++) {
          if (arrayDragNdrop[i] === arrayLanguages_[j].id) {
            arraySorted.push({
              name: arrayLanguages_[j].name,
              id: arrayDragNdrop[i],
              isActive: arrayLanguages_[j].isActive,
              flag: arrayLanguages_[j].flag,
            });
          }
        }
      }
    });

    this.arrayLanguages = arraySorted;
  }
}
