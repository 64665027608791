import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-date-selector",
  templateUrl: "./date-selector.component.html",
  styleUrls: ["./date-selector.component.scss"],
})
export class DateSelectorComponent implements OnInit {
  @Output() filter: EventEmitter<any> = new EventEmitter();
  constructor() {}

  dateVals = ["This Year", "This Month", "This Week"];
  currentVal = 0;

  ngOnInit() {}

  goBack() {
    if (this.currentVal === 0) return;
    this.currentVal--;
    this.filter.emit(this.currentVal + 1);
  }

  goNext() {
    if (this.currentVal >= this.dateVals.length - 1) return;
    this.currentVal++;
    this.filter.emit(this.currentVal + 1);
  }
}
