export class AppConstants {
  public static CommunicationPlatForm = false;

  public static get baseURL(): string {
    // prod WWTC
    // return "https://apiforms.worldwidetechconnections.com/api";

    return "https://if9y5xy3bj.execute-api.us-east-1.amazonaws.com/prod";
  }
}
