import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "util";
import { Observable } from "rxjs";
import { User } from "./models/user";
import { AppConstants } from "../constants";

@Injectable()
/**
 * User Authentication Service
 */
export class AuthService {
  _baseURL: string;
  public test: string;
  public cp: any;
  public timer: any;
  public fakeData: any;
  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.baseURL;

    this.test = "hola";
  }

  /**
   *Receive user data to be registered
   */
  registerUser(
    email: string,
    password: string,
    password_confirmation: string,
    company_name: string,
    fullname: string
  ): Observable<any> {
    const url = `${this._baseURL}/register`;
    const body = {
      email,
      password,
      password_confirmation,
      company_name,
      fullname,
    };

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      })
      .pipe(map((data) => data));
  }

  /**
   *Receive email and new password from user to change password
   */
  changePassword(
    email: string,
    token: string,
    password: string,
    password_confirmation: string
  ): Observable<any> {
    const url = `${this._baseURL}/user/changepassword`;

    const body = {
      email,
      token,
      password,
      password_confirmation,
    };

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      })
      .pipe(map((data) => data));
  }

  /**
   *Receive email and password for user login
   */
  loginUser(email: string, password: string): Observable<any> {
    const url = `${this._baseURL}/login`;
    return this.http.post(url, { email, password }).pipe(map((data) => data));
  }
  fakeLoginUser(email: string, password: string): Observable<any> {
    const body = {
      email: email,
      password: password,
      company_name: "",
      fullname: email,
    };
    const url = `${this._baseURL}/register-plataform`;
    console.log(url);

    return this.http.post(url, body).pipe(map((data) => data));
  }
  /* getJSONbyTokenLanguage(jsonToken, code): Observable<any> {
    const url = `${this._baseURL}/forms/token/${jsonToken}?code=${code}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json"
        })
      })
      .pipe(map(data => data));
  } */

  /**
   *Receive email and make password recovery request
   */
  sendEmail(email: string): Observable<any> {
    const url = `${this._baseURL}/user/recover?email=${email}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      })
      .pipe(map((data) => data));
  }

  /**
   *Save the necessary user data in the local Storage
   */
  setUser(user) {
    let user_string = JSON.stringify(user);

    localStorage.setItem("current_user", user_string);
  }

  /**
   *Save the logged-in user's token in the local Storage
   */
  setToken(token) {
    localStorage.setItem("accessToken", "Bearer " + token);
  }

  /**
   *Gets the user's token logged into the local Storage
   */
  getToken() {
    return localStorage.getItem("accessToken");
  }

  /**
   *Gets the data of the logged-in user
   */
  getCurrentUser() {
    let user_string = localStorage.getItem("current_user");
    if (!isNullOrUndefined(user_string)) {
      let user = JSON.parse(user_string);
      return user;
    } else {
      return null;
    }
  }

  /**
   *Gets the data of the logged-in user
   */
  logoutUser() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("current_user");
  }
  /**
   *Receive password reset data and request updatePassword
   */
  resetPassword(
    email: String,
    password: String,
    password_new: String,
    password_confirmation: String,
    token: string
  ): Observable<any> {
    const url = `${this._baseURL}/user/updatePassword`;

    const body = {
      email,
      password,
      password_new,
      password_confirmation,
    };

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }
}
