import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConstants } from "../constants";
import { map } from "rxjs/internal/operators/map";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguagesService {
  _baseURL: string;

  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.baseURL;
  }

  getLanguages(token): Observable<any> {
    const url = `${this._baseURL}/language`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  getLanguagesNew(): Observable<any> {
    const url = "https://core.worldwidetechconnections.com/languages";
    return this.http.get(url, {}).pipe(map((data) => data));
  }
}
