import { Label } from "ng2-charts";
import { Component, OnInit } from "@angular/core";
import { StatsService } from "../services/stats.service";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.scss"],
})
export class AdminDashboardComponent implements OnInit {
  public loading: boolean;
  public tab: boolean;
  public userStats: boolean;
  public dataResponse: any;
  public dataResponse2: any;
  public listUsers: any;
  public dataGeneral: any;
  public dataUser: any;
  public labels = [];
  public topLanguages: any;
  public dataGraph = [];
  public userDataStats: any;
  public userDataStatsApp: any;
  public actualUser: any;
  public fechaInicio: string;
  public fechaFin: string;
  public range: number;

  // doughnut, bar, pie, line
  public tipo = "line";
  constructor(
    private statsService: StatsService,
    private authService: AuthService
  ) {
    this.tab = true;
    this.loading = true;
    this.userStats = false;
    this.fechaInicio = "01-01-1900";
    this.fechaFin = "25-05-2500";
    this.range = 3;

    this.loadGeneralData(this.range);
  }

  ngOnInit() {}

  filter(event) {
    this.fechaInicio = event.inicio;
    this.fechaFin = event.fin;
    this.range = event.tipo_rango;
    console.log(this.fechaInicio, this.fechaFin);

    this.loadGeneralData(this.range);
    if (this.userStats) {
      this.loadUserData(
        this.actualUser,
        this.fechaInicio,
        this.fechaFin,
        this.range
      );
    }
  }
  cambiardata() {}
  active(tab) {
    this.tab = tab;
    this.userStats = false;
  }
  loadGeneralData(filter) {
    console.log("entro");

    this.statsService.generalStats(this.authService.getToken()).subscribe(
      (response) => {
        this.dataResponse = response;
        this.listUsers = response.forms_user;

        var tempArray = response.top_languages;
        tempArray.sort(function (a, b) {
          var keyA = new Date(a.count),
            keyB = new Date(b.count);
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });

        console.log(tempArray);

        this.topLanguages = tempArray.slice(0, 10);
        console.log("RESPONSE 1 ", response);
      },
      (error) => {},
      () => {
        this.statsService
          .generalStatsApp(
            this.authService.getToken(),
            this.fechaInicio,
            this.fechaFin,
            filter
          )
          .subscribe(
            (response2) => {
              this.dataResponse2 = response2;
              console.log("RESPONSE 2 ", response2);
            },
            (error) => {
              console.log(error);
            },
            () => {
              for (
                let index = 0;
                index < this.dataResponse2.user_grafic.length;
                index++
              ) {
                this.labels.push(
                  this.dataResponse2.user_grafic[index].range.toString()
                );
                this.dataGraph.push(
                  this.dataResponse2.user_grafic[index].count
                );
              }

              this.dataGeneral = [
                {
                  icon: "fas fa-hashtag",
                  title: this.dataResponse2.total_user_conected,
                  subTitle: "AVG USERS PER DAY",
                  color: "#ed8e3c",
                },
                {
                  icon: "fas fa-clock",
                  title: this.dataResponse2.prom_use_application_minutes,
                  subTitle: "AVG TIME PER FORM",
                  color: "#89c2cb",
                  time: "min",
                },
                {
                  icon: "fas fa-list-alt",
                  title: this.dataResponse.total,
                  subTitle: "AVG NUM OF FORMS",
                  color: "#4baa46",
                },
                {
                  icon: "far fa-check-square",
                  title: this.dataResponse.total_answers,
                  subTitle: "AVG ANSWERS P/FORM",
                  color: "#003c74",
                },
              ];
              this.loading = false;
            }
          );
      }
    );
  }
  loadUserData(user, start, end, range) {
    this.actualUser = user;
    /* if (start === undefined || start === "") {
      start = this.fechaInicio;
      end = this.fechaFin;
      range = this.range;
    } */
    this.statsService.userStats(this.authService.getToken(), user).subscribe(
      (response) => {
        this.userDataStats = response;
        console.log("userDataStats", this.userDataStats);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.statsService
          .userStatsApp(this.authService.getToken(), start, end, user, range)
          .subscribe(
            (response2) => {
              this.userDataStatsApp = response2;
              console.log("userDataStatsApp", this.userDataStatsApp);
            },
            (error) => {},
            () => {
              this.dataUser = [
                {
                  icon: "fas fa-chart-line",
                  title: this.stringSplit(this.userDataStats.date_created),
                  subTitle: "Register date",
                  color: "#ed8e3c",
                },
                {
                  icon: "fas fa-chart-line",
                  title: this.userDataStats.forms_active,
                  subTitle: "Active Forms",
                  color: "#89c2cb",
                },
                {
                  icon: "fas fa-chart-line",
                  title: this.userDataStats.forms_inactive,
                  subTitle: "Inactive Forms",
                  color: "#4baa46",
                },
                {
                  icon: "fas fa-chart-line",
                  title: this.userDataStats.forms_private,
                  subTitle: "Private Forms",
                  color: "#4baa46",
                },
                {
                  icon: "fas fa-chart-line",
                  title: this.userDataStats.forms_public,
                  subTitle: "Public Forms",
                  color: "#4baa46",
                },
                {
                  icon: "fas fa-chart-line",
                  title: this.userDataStats.forms_visits,
                  subTitle: "Visited Forms",
                  color: "#4baa46",
                },
                {
                  icon: "fas fa-chart-line",
                  title: this.stringSplit(this.userDataStats.last_activity),
                  subTitle: "Last Activite",
                  color: "#4baa46",
                },
                {
                  icon: "fas fa-chart-line",
                  title: this.userDataStats.total_forms,
                  subTitle: "Total Forms",
                  color: "#4baa46",
                },
                {
                  icon: "fas fa-chart-line",
                  title: this.userDataStats.total_answers,
                  subTitle: "Total Answers",
                  color: "#4baa46",
                },
              ];
            }
          );
      }
    );
  }
  showStats(user) {
    if (user !== undefined) {
      this.loadUserData(user, this.fechaInicio, this.fechaFin, this.range);
    }

    this.userStats = !this.userStats;
  }
  stringSplit(string) {
    string = this.userDataStats.date_created.split("-");

    let string2 = string[2].split("T");
    let date = string[0] + "-" + string[1] + "-" + string2[0];
    return date;
  }
}
