import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { NewFormStep1Component } from "./step1/new-form-step1.component";
import { NewFormStep2Component } from "./step2/new-form-step2.component";
import { NewFormConfirmationComponent } from "./confirmation/new-form-confirmation.component";
import { ConfigFormComponent } from "./config-form/config-form.component";
import { PreviewFormComponent } from "./preview-form/preview-form.component";
const routes: Routes = [
  { path: "step1", component: NewFormStep1Component },
  { path: "step1/:id", component: NewFormStep1Component },
  { path: "step2", component: NewFormStep2Component },
  { path: "config", component: ConfigFormComponent },
  { path: "preview", component: PreviewFormComponent },
  { path: "confirmation", component: NewFormConfirmationComponent },
  {
    path: "",
    redirectTo: "step1",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "step1",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewFormRoutingModule {}
