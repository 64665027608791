import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConstants } from "../../constants";
import { map } from "rxjs/internal/operators/map";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StatsService {
  _baseURL: string;
  public sessionTime: any;

  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.baseURL;
    this.sessionTime = 0;
  }

  generalStats(token): Observable<any> {
    const url = `${this._baseURL}/statistics_general`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  userStats(token, user): Observable<any> {
    const url = `${this._baseURL}/statistics_user/${user}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  generalStatsApp(token, start, end, range): Observable<any> {
    console.log(token, start, end, range);

    //range: 1 Year / 2 Month / 3 Weeks
    const url = `${this._baseURL}/use_application?start=${start}&end=${end}&type_range=${range}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  userStatsApp(token, start, end, user, range): Observable<any> {
    //range: 1 Year / 2 Month / 3 Weeks
    const url = `${this._baseURL}/use_application?start=${start}&end=${end}&email=${user}&type_range=${range}`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }

  closeSession(token): Observable<any> {
    const url = `${this._baseURL}/logUser`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        }),
      })
      .pipe(map((data) => data));
  }
}
