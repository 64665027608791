import { DynamicFormService } from "./../../../services/dynamic-form.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguagesService } from "src/app/services/languages.service";

@Component({
  selector: "app-answer-dashboard",
  templateUrl: "./answer-dashboard.component.html",
  styleUrls: ["./answer-dashboard.component.scss"],
})
export class AnswerDashboardComponent implements OnInit {
  public data: Array<any>;
  public id_form;
  public nameOrder;
  public updateOrder;
  public isLoading;
  public headersToTable: Array<any>;
  public dataToTable: Array<any>;
  public actionsColumnsToTable: Array<any>;
  public hasAnswers;
  public arrayLanguages;
  public totalPage: Array<any>;
  public userHasAnwers: boolean;
  public dashboardEmpty: boolean;
  public mostrar: Array<any>;
  public actualPage;
  public resultPerPage;
  public disablePrevius: boolean;
  public disableNext: boolean;

  public actionsColumns = ["goFormAnswers", "goFormAnswers", "goFormAnswers"];

  constructor(
    private _dynamicFormService: DynamicFormService,
    private authService: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private languagesService: LanguagesService
  ) {
    this.nameOrder = false;
    this.updateOrder = false;
    this.isLoading = true;
    this.headersToTable = [
      { text: "Submitted at", sort: true },
      { text: "Language", sort: true },
      { text: "Translation", sort: true },
      { text: "Signature", sort: true },
    ];
    this.dataToTable = [];
    this.actionsColumnsToTable = ["", "", "", "", ""];
    this.hasAnswers = false;
    this.arrayLanguages = [];
    this.userHasAnwers = false;
    this.dashboardEmpty = false;
    this.actualPage = 1;
    this.resultPerPage = 50;
    this.disablePrevius = false;
    this.disableNext = false;
  }

  ngOnInit() {
    this._route.paramMap.subscribe((params) => {
      this.id_form = params.get("id");
    });

    this.getAnswers();
  }

  getAnswers() {
    this._dynamicFormService
      .listAnswers(
        this.authService.getToken(),
        this.id_form,
        this.actualPage,
        this.resultPerPage
      )
      .subscribe(
        (response) => {
          console.log(response);

          this.totalPage = new Array(response.data.last_page);

          if (response.data.total > 0) this.userHasAnwers = true;
          else this.dashboardEmpty = true;

          if (response.data.last_page > 4) {
            this.mostrar = new Array(5);
          } else {
            this.mostrar = new Array(response.data.last_page);
          }

          for (let index = 0; index < this.mostrar.length; index++) {
            this.mostrar[index] = index + 1;
          }
          if (this.actualPage == 1) {
            this.disablePrevius = true;
          } else {
            this.disablePrevius = false;
          }

          if (this.actualPage === this.totalPage.length) {
            this.disableNext = true;
          } else {
            this.disableNext = false;
          }

          this.data = response.data.Items;

          if (response.message === "Success") {
            this.dataTable(this.data);
            this.hasAnswers = true;
          } else {
            this.hasAnswers = false;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  dataTable(data) {
    this.languagesService.getLanguagesNew().subscribe(
      (response) => {
        for (let i = 0; i < response.length; i++) {
          const element = response[i];

          this.arrayLanguages.push({
            name: element.name,
            id: element.code,
            isActive: false,
            flag: element.flag,
          });
        }
      },
      (error) => {},
      () => {
        this.dataToTable = [];

        for (let index = 0; index < data.length; index++) {
          var code = this.data[index].code_language;
          // code = "es";

          var imgObj;
          if (code === "en") {
            imgObj = {
              img: true,
              url: "./assets/flags/united_states.png",
              title: "English",
            };
          } else {
            var langIndex = this.arrayLanguages.findIndex(function (item, i) {
              return item.id === code;
            });

            imgObj = {
              img: true,
              url: this.arrayLanguages[langIndex].flag,
              title: this.arrayLanguages[langIndex].title,
            };
          }

          const { translated_answer } = data[index];

          if (this.data[index].answerData.signature_url === "emphy") {
            this.dataToTable.push({
              param1: this.data[index].updated_at,
              param2: [imgObj],
              param3: translated_answer ? "Done" : "In progress",
              param4: "",
            });
          } else {
            this.dataToTable.push({
              param1: this.data[index].updated_at,
              param2: [imgObj],
              param3: translated_answer ? "Done" : "In progress",
              param4: [
                {
                  signature: true,
                  url: this.data[index].answerData.signature_url,
                },
              ],
            });
          }
        }
      }
    );
  }

  actionTable(e) {
    console.log(this.data[e.index]);

    if (e.action == "goFormAnswers") {
      this._router.navigate([
        "/user-forms/answer-form/" + this.data[e.index].id,
      ]);
    }
  }

  perPage(number) {
    this.resultPerPage = number;
    this.actualPage = 1;
    this.getAnswers();
  }

  /**
   * Goes to the selected page
   */
  page(page) {
    this.actualPage = page;

    this.getAnswers();
  }

  /**
   * Goes to the previous page
   */
  previousPage() {
    if (this.actualPage > 1) {
      if (this.actualPage == this.mostrar[0]) {
        for (let index = 0; index < this.mostrar.length; index++) {
          this.mostrar[index] = this.mostrar[index] - 1;
        }
      }
      this.actualPage = this.actualPage - 1;
      this.getAnswers();
    }
  }

  /**
   * Goes to the next page
   */
  nextPage() {
    if (this.actualPage < this.totalPage.length) {
      if (this.actualPage == this.mostrar[this.mostrar.length - 1]) {
        for (let index = 0; index < this.mostrar.length; index++) {
          this.mostrar[index] = this.mostrar[index] + 1;
        }
      }
      this.actualPage = this.actualPage + 1;
      this.getAnswers();
    }
  }
}
