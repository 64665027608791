import { Component, OnInit } from "@angular/core";
import { DynamicFormService } from "src/app/services/dynamic-form.service";
import { AuthService } from "src/app/auth/auth.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-leads-form",
  templateUrl: "./leads-form.component.html",
  styleUrls: ["./leads-form.component.scss"],
})
export class LeadsFormComponent implements OnInit {
  public data: Array<any>;
  public id_form;
  public token;
  public nameOrder;
  public updateOrder;
  public isLoading;
  public headersToTable: Array<any>;
  public dataToTable: Array<any>;
  public actionsColumnsToTable: Array<any>;
  constructor(
    private _dynamicFormService: DynamicFormService,
    private authService: AuthService,
    private _route: ActivatedRoute
  ) {
    this.nameOrder = false;
    this.updateOrder = false;
    this.isLoading = true;
    this.headersToTable = [
      { text: "First name", sort: true },
      { text: "	Last Name", sort: true },
      { text: "Email", sort: true },
      { text: "Phone", sort: true },
      { text: "Date", sort: true },
      { text: "Company", sort: false },
    ];
    this.dataToTable = [];
    this.actionsColumnsToTable = ["", "", "", "", ""];
  }

  ngOnInit() {
    this.token = this.authService.getToken();
    this._route.paramMap.subscribe((params) => {
      this.id_form = params.get("id");
    });
    this._dynamicFormService.getLeads(this.id_form, this.token).subscribe(
      (response) => {
        this.data = response.data;
        this.dataTable(this.data);
        console.log(this.data);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  dataTable(data) {
    this.dataToTable = [];
    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index].company_user === "emphy") {
        this.data[index].company_user = "";
      }
      this.dataToTable.push({
        param1: this.data[index].name_user,
        param2: this.data[index].last_name_user,
        param3: this.data[index].email_user,
        param4: this.data[index].phone_user,
        param5: this.data[index].created_at,
        param6: this.data[index].company_user,
      });
    }
  }
}
