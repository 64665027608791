import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { DynamicFormService } from "src/app/services/dynamic-form.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  isLogged: boolean;
  constructor(private _router: Router, private authService: AuthService) {
    this.isLogged = false;

    if (this.authService.getCurrentUser()) {
      this.isLogged = true;
    }
  }

  ngOnInit() {}
  /**
   * Close user session
   */
  logout() {
    this.authService.logoutUser();
    this._router.navigate(["/login"]);
  }
  /**
   * Go to user dashboard
   */
  goDashboard() {
    if (this.isLogged) {
      this._router.navigate(["/user-forms/dashboard"]);
    } else {
      this._router.navigate(["/login"]);
    }
  }
}
