import {
  Component,
  OnInit,
  ɵConsole,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import { FormArray, Validators, FormGroup, FormControl } from "@angular/forms";
import { DynamicFormService } from "src/app/services/dynamic-form.service";
import { formCol } from "../../user-forms/models/formCol.model";
import swal from "sweetalert2";
import { formRow } from "src/app/user-forms/models/formRow.model";
import { colElement } from "src/app/user-forms/models/colElement.model";
import { SignaturePad } from "angular2-signaturepad/signature-pad";
import { DomSanitizer } from "@angular/platform-browser";

import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from "ngx-file-drop";

import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { value } from "src/app/global.model";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-new-form-step2",
  templateUrl: "./new-form-step2.component.html",
  styleUrls: ["./new-form-step2.component.scss"],
})
export class NewFormStep2Component implements OnInit {
  public opcionSeleccionado: string;
  public numberArray: number;
  public array: Array<number>;
  public arrayPlaceholder: Array<number>;
  public form: FormGroup;
  public acum: number;
  public nombre: string;
  public publicTitle: String;
  public instructions: String;
  public validateTotalwidth: boolean;
  public validateMinWitdh: boolean;
  public formRowPrototipe: Array<FormGroup>;
  public element: String;
  public arrayCol: formCol[];
  public arrayRow: formRow[];
  public arrayElement: any[];
  public rowBone: Array<any>;
  public colBone: Array<any>;
  public oneColumn: number;
  public selectedRow: any;
  public selectedIndex: any;
  public onlyCanvas: boolean;
  public onlyElements: boolean;
  public major: boolean;
  public signature;
  public isPrivate;
  public publicTitleCheck;
  public instructionsCheck;
  public logoBase64: any;
  public lengthError: String;
  public arrayColumns;
  public arrayTemp;
  public formTemp;
  public files: NgxFileDropEntry[] = [];
  public imageUrl;
  public sliderValue = 1;
  public hideHeader = false;
  public hideContent = false;
  public onBoardingStepsHeader = [];
  public onBoardingStepsContent = [];
  public activeOnBoarding = true;
  public user: any;
  public oldValue;
  public newValue;
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

  signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 350,
    canvasHeight: 90,
  };

  constructor(
    private _dynamicForm: DynamicFormService,
    protected sanitizer: DomSanitizer,
    private authService: AuthService
  ) {
    this.formRowPrototipe = [];
    this._dynamicForm.formRow = [];
    this._dynamicForm.colX = 0;
    this.oneColumn = 100;
    this.arrayTemp = [];

    this.user = JSON.parse(localStorage.getItem("current_user"));

    this.activeOnBoarding = this.user.onboarding;
    this.configOnBoarding();
  }
  aux = 0;
  ngOnInit() {
    this.validateTotalwidth = false;
    this.validateMinWitdh = false;
    this.acum = 100;
    this.form = new FormGroup({
      columns: new FormArray([]),
    });
    this.rowBone = [];
    this.colBone = [];
    this.formRowPrototipe = [];
    this.onlyCanvas = true;
    this.onlyElements = true;
    this.arrayElement = [];
    this.arrayRow = [];

    this.arrayCol = [];
    this.numberArray = 1;
    if (this._dynamicForm.tempArrayRow) {
      this.arrayRow = this._dynamicForm.tempArrayRow;
    }

    if (
      this._dynamicForm.editForm &&
      Object.keys(this._dynamicForm.editForm).length > 0
    ) {
      this.getRows();
      this.getPrototipe();

      if (this._dynamicForm.editForm.publicTitle === "emphy") {
        this._dynamicForm.editForm.publicTitle = "";
      }
      if (this._dynamicForm.editForm.instructions === "emphy") {
        this._dynamicForm.editForm.instructions = "";
      }
      this.publicTitle = this._dynamicForm.editForm.publicTitle;
      this.instructions = this._dynamicForm.editForm.instructions;

      if (!this._dynamicForm.formPreview) {
        this._dynamicForm.publicTitleCheck =
          this._dynamicForm.editForm.publicTitleCheck;
        this._dynamicForm.instructionsCheck =
          this._dynamicForm.editForm.instructionsCheck;
      }

      if (
        this._dynamicForm.editForm.logo_url &&
        this._dynamicForm.editForm.logo_url != "emphy"
      ) {
        this.logoBase64 = this.sanitizer.bypassSecurityTrustUrl(
          this._dynamicForm.editForm.logo_url
        );
        this._dynamicForm.logoBase64 = this.logoBase64;
      }
      this.arrayPlaceholder = [0, 0, 0, 0, 0, 0];
      console.log(this._dynamicForm.editForm.logo_url);
    }
    if (this._dynamicForm.formPreview != undefined) {
      this.formRowPrototipe = this._dynamicForm.formPreview;
      this.arrayRow = this._dynamicForm.previewJson;
      this.arrayPlaceholder = this._dynamicForm.placeholder;
    }

    this.signature = this._dynamicForm.signature;
    this.isPrivate = this._dynamicForm.isPrivate;

    this.publicTitleCheck = true;
    this.instructionsCheck = true;

    if (this._dynamicForm.formName) {
      this.publicTitle = this._dynamicForm.formName;
    }

    if (this._dynamicForm.publicTitle) {
      this.publicTitle = this._dynamicForm.publicTitle;
    }

    if (this._dynamicForm.instructions) {
      this.instructions = this._dynamicForm.instructions;
    }

    if (this._dynamicForm.logoBase64) {
      this.logoBase64 = this._dynamicForm.logoBase64;
    }

    this.publicTitleCheck = this._dynamicForm.publicTitleCheck;
    this.instructionsCheck = this._dynamicForm.instructionsCheck;
  }

  /**
   * Sets the major flag on true
   */
  ngOnDestroy(): void {
    console.log(this.isPrivate);
  }

  majorLength() {
    if (this.instructions.length >= 1400) {
      this.major = true;
    }
  }

  /**
   * Shows the image uploaded by user
   * @param event
   */
  showPreviewImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      this._dynamicForm.logoFile = event.target.files[0];

      let fileType = this._dynamicForm.logoFile.type.substr(0, 5);

      if (fileType === "image") {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.logoBase64 = event.target.result;
          this._dynamicForm.logoBase64 = this.logoBase64;

          this._dynamicForm.logoBase64 = event.target.result;
          this.logoBase64 = this._dynamicForm.logoBase64;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  /**
   * Sets the number of columns selectd by user in the slider
   */
  getColumn(event?) {
    if (event != null) {
      this.oldValue = event.oldValue;
      this.newValue = event.newValue;
    }

    if (this.activeOnBoarding) {
      if (this.onBoardingStepsContent[1].active == true) {
        this.onBoardingStepsContent[1].active = false;
        this.onBoardingStepsContent[2].active = true;
      }
      document.getElementById("content").scrollIntoView();
    }

    this.validateTotalwidth = false;
    this.validateMinWitdh = false;

    if (event == undefined) {
      this.opcionSeleccionado = "1";
      this.numberArray = +this.opcionSeleccionado;
      this.array = new Array(this.numberArray);
      this.arrayPlaceholder = new Array(this.numberArray);
      this.sliderValue = 1;
      this.arrayPlaceholder[0] = 100;
      this.acum = 100;
    } else {
      this.opcionSeleccionado = "" + event.newValue;
      this.sliderValue = event.newValue;
      this.numberArray = +this.opcionSeleccionado;
      this.array = new Array(this.numberArray);
      this.arrayPlaceholder = new Array(this.numberArray);
      let valuePlaceholder = Math.trunc(100 / this.array.length);

      for (let index = 0; index < this.array.length; index++) {
        this.arrayPlaceholder[index] = valuePlaceholder;
        this.array[index] = valuePlaceholder;
      }

      this.array[this.array.length - 1] =
        100 - valuePlaceholder * (this.array.length - 1);
      this.arrayPlaceholder[this.arrayPlaceholder.length - 1] =
        100 - valuePlaceholder * (this.arrayPlaceholder.length - 1);

      if (event.newValue == 1) {
        this.acum = 100;
      }
    }
    this.form = new FormGroup({
      columns: new FormArray([]),
    });
    (<FormArray>this.form.controls["columns"]).clear();
    for (let index = 0; index < this.array.length; index++) {
      (<FormArray>this.form.controls["columns"]).push(
        new FormGroup({
          col: new FormControl(this.arrayPlaceholder[index], [
            Validators.required,
            Validators.min(10),
            Validators.pattern("[0-9]+"),
          ]),
          elements: new FormArray([]),
        })
      );
    }
    for (let index = 0; index < this.arrayPlaceholder.length; index++) {
      this.form.controls["columns"].value[index].col =
        this.arrayPlaceholder[index];
    }
  }

  /**
   * It validates that total width percentage entered by the user, is exactly 100%, considering the individual width of each column.
   */
  totalWidthColumns() {
    this.acum = 0;
    let band = true;
    this.numberArray = +this.opcionSeleccionado;
    this.array = new Array(this.numberArray);

    for (let index = 0; index < this.array.length; index++) {
      if (this.form.controls["columns"].value[index].col)
        this.acum =
          this.acum + Number(this.form.controls["columns"].value[index].col);
    }

    for (let index = 0; index < this.array.length; index++) {
      if (this.form.controls["columns"].value[index].col == "") {
        band = false;
      } else {
        band = true;
      }
    }

    if (band == true && this.acum != 100) {
      this.validateTotalwidth = true;
    } else {
      this.validateTotalwidth = false;
    }
  }

  /**
   * Validates that the minimum width entered by the user is equal to 10%
   */
  minWitdh(number) {
    let band;
    if (this.form.controls["columns"].value[number].col >= 10) {
      this.validateMinWitdh = false;
      this.dynamicPlaceHolder();
    } else {
      this.validateMinWitdh = true;
    }
  }

  /**
   * Dynamically sets the values of the column widths to equal 100%
   */
  dynamicPlaceHolder() {
    let cont = 0;
    for (let index = 0; index < this.arrayPlaceholder.length; index++) {
      if (this.form.controls["columns"].value[index] != undefined) {
        if (this.form.controls["columns"].value[index].col > 0) {
          cont++;
        }
      }
    }
    if (cont < this.arrayPlaceholder.length) {
      let columsRest = this.arrayPlaceholder.length - cont;
      let valuePlaceholder = Math.trunc(
        (100 - this.acum) / (this.arrayPlaceholder.length - cont)
      );

      for (let index = 0; index < this.arrayPlaceholder.length; index++) {
        this.arrayPlaceholder[index] = valuePlaceholder;
      }
      this.arrayPlaceholder[this.arrayPlaceholder.length - 1] =
        100 - (valuePlaceholder * (columsRest - 1) + this.acum);

      /* this.arrayPlaceholder[this.arrayPlaceholder.length - 1] =
        100 - valuePlaceholder * (this.arrayPlaceholder.length - 1); */
    }
  }

  /**
   * Adds a new row pushing into formRowPrototipe the current formGroup
   */
  createRow() {
    this.formRowPrototipe.push(this.form);
    this.opcionSeleccionado = null;

    if (
      this.onBoardingStepsContent[2].active == true ||
      this.onBoardingStepsContent[3].active == true
    ) {
      this.onBoardingStepsContent[2].active = false;
      this.onBoardingStepsContent[3].active = false;
      this.onBoardingStepsContent[4].active = true;
    }
  }

  /**
   * Saves a prototype of the form and then saves it in the models
   */
  saveForm() {
    this.arrayRow = [];
    this.arrayCol = [];
    this.arrayElement = [];
    this._dynamicForm.tempArrayRow = [];
    let cont = 0;
    for (let row = 0; row < this.formRowPrototipe.length; row++) {
      for (
        let col = 0;
        col < this.formRowPrototipe[row].controls.columns["controls"].length;
        col++
      ) {
        this.formRowPrototipe[row].controls.columns["controls"][col].controls[
          "elements"
        ].clear();
      }
    }
    for (let row = 0; row < this.formRowPrototipe.length; row++) {
      for (
        let col = 0;
        col < this.formRowPrototipe[row].controls.columns["controls"].length;
        col++
      ) {
        (<FormArray>(
          this.formRowPrototipe[row].controls.columns["controls"][col].controls[
            "elements"
          ]
        )).push(
          new FormGroup({
            element: new FormControl(
              this._dynamicForm.formRow[this._dynamicForm.colX]
            ),
          })
        );
        this._dynamicForm.colX++;
        cont++;
      }
    }
    this.setModels();
  }
  /**
   * Go through the formed prototype and save it in the models of the form
   */
  setModels() {
    this.arrayElement = [];
    this.arrayCol = [];

    for (let row = 0; row < this.formRowPrototipe.length; row++) {
      for (
        let col = 0;
        col < this.formRowPrototipe[row].controls.columns["controls"].length;
        col++
      ) {
        for (
          let element = 0;
          element <
          this.formRowPrototipe[row].controls.columns["controls"][col].controls[
            "elements"
          ].value.length;
          element++
        ) {
          for (
            let atribute = 0;
            atribute <
            this.formRowPrototipe[row].controls.columns["controls"][col]
              .controls["elements"].value[element].element.attributes.length;
            atribute++
          ) {
            this.arrayElement.push(
              new colElement(
                element,
                col,
                this.formRowPrototipe[row].controls.columns["controls"][
                  col
                ].controls["elements"].value[element].element.attributes[
                  atribute
                ].type,
                this.formRowPrototipe[row].controls.columns["controls"][
                  col
                ].controls["elements"].value[element].element.attributes[
                  atribute
                ].label,
                this.formRowPrototipe[row].controls.columns["controls"][
                  col
                ].controls["elements"].value[element].element.attributes[
                  atribute
                ].required,
                "",
                this.formRowPrototipe[row].controls.columns["controls"][
                  col
                ].controls["elements"].value[element].element.attributes[
                  atribute
                ].name,
                this.formRowPrototipe[row].controls.columns["controls"][
                  col
                ].controls["elements"].value[element].element.attributes[
                  atribute
                ].placeholder,
                this.formRowPrototipe[row].controls.columns["controls"][
                  col
                ].controls["elements"].value[element].element.attributes[
                  atribute
                ].values
              )
            );
          }
        }
        this.arrayCol.push(
          new formCol(
            col,
            row,
            this.formRowPrototipe[row].controls.columns["controls"][
              col
            ].controls.col.value,
            this.arrayElement
          )
        );
        this.arrayElement = [];
      }
      this.arrayRow.push(new formRow(row, null, this.arrayCol));
      this.arrayCol = [];
    }
    this._dynamicForm.publicTitle = this.publicTitle;
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    this._dynamicForm.instructions = this.instructions;
    this._dynamicForm.formPreview = this.formRowPrototipe;
    this._dynamicForm.previewJson = this.arrayRow;
    this._dynamicForm.tempArrayRow = this.arrayRow;
    this._dynamicForm.signature = this.signature;
    this._dynamicForm.isPrivate = this.isPrivate;
    this._dynamicForm.placeholder = this.arrayPlaceholder;
  }

  /**
   * Returns the array that contains all the rows
   */
  getRowModel() {
    return this.arrayRow;
  }

  /**
   * Receives the data from the child component edit-app
   */
  dataElements(elements) {
    this._dynamicForm.formRow.push(elements);
  }
  /**
   * When onBoarding is active and the first element is added to the canvas, it disables the 2 steps performed to show the next step
   */
  firtsElement(firtsElement) {
    if (this.activeOnBoarding) {
      this.onBoardingStepsContent[4].active = false;
      this.onBoardingStepsContent[5].active = false;
      this.onBoardingStepsContent[6].active = true;
    }
  }

  /**
   * Get the selected row and opens the modal to edit the row
   */
  editRow(row, index) {
    this.formTemp = JSON.parse(
      JSON.stringify(this.formRowPrototipe[index].value)
    ) as FormGroup;

    this.sliderValue = this.formTemp.columns.length;
    for (let index = 0; index < this.formTemp.columns.length; index++) {
      this.arrayPlaceholder[index] = this.formTemp.columns[index].col;
    }

    this.selectedRow = row;
    this.selectedIndex = index;

    this.arrayColumns = this.selectedRow.value.columns;
    this.opcionSeleccionado = this.arrayColumns.length;
    this.form = this.selectedRow;

    document.getElementById("btn-editRow").click();

    //fix update row width no elements first preview
    this.oldValue = this.newValue;
  }

  /**
   * Restore the previous form objetct when the user close the edit modal
   */
  closeEdit() {
    this.form.setValue(this.formTemp);
  }

  /**
   * Deletes the row by the given index
   */
  deleteRow(index) {
    swal({
      title: "Are you sure?",
      text: "Do you want to remove this row?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00B96F",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove!",
    }).then((result) => {
      if (result.value) {
        for (let row = 0; row < this.formRowPrototipe.length; row++) {
          for (
            let col = 0;
            col <
            this.formRowPrototipe[row].controls.columns["controls"].length;
            col++
          ) {
            this.formRowPrototipe[row].controls.columns["controls"][
              col
            ].controls["elements"].clear();
          }
        }

        for (let row = 0; row < this.formRowPrototipe.length; row++) {
          for (
            let col = 0;
            col <
            this.formRowPrototipe[row].controls.columns["controls"].length;
            col++
          ) {
            this.formRowPrototipe[row].controls.columns["controls"][
              col
            ].controls["elements"].clear();
          }
        }

        this.formRowPrototipe.splice(index, 1);
        this._dynamicForm.previewJson.splice(index, 1);
        this.arrayRow.splice(index, 1);

        this._dynamicForm.colX = 0;
        for (let i = 0; i < this._dynamicForm.formRow.length; i++) {
          if (this._dynamicForm.formRow[i]["row"] === index) {
            this._dynamicForm.formRow.splice(
              i,
              this._dynamicForm.formRow.length
            );
            this.rowBone.splice(index, 1);
            break;
          }
        }
        for (let j = index; j < this._dynamicForm.formRow.length; j++) {
          this._dynamicForm.formRow[j]["row"] =
            this._dynamicForm.formRow[j]["row"] - 1;
        }
      }
    });
  }

  /**
   * Updates the row defined in selectedIndex
   */
  updateRow() {
    //fix update row width no elements first preview
    if (this.newValue != this.oldValue) {
      this.oldValue = this.newValue;
      this.saveForm();
    }

    this.formRowPrototipe.splice(this.selectedIndex, 1);
    this.formRowPrototipe.splice(this.selectedIndex, 0, this.form);

    this.opcionSeleccionado = null;
  }

  /**
   * Is triggered when the user finishes the signature
   */
  drawComplete() {}

  /**
   * Is triggered when the user starts the signature
   */
  drawStart() {}

  /**
   * Clear the canvas of signature
   */
  clearSignature() {
    this.signaturePad.clear();
  }
  /**
   * When editing, go to the editForm service variable to load my models with the form information
   */
  getRows() {
    this.arrayRow = [];
    this.arrayElement = [];
    this.arrayCol = [];

    for (let row = 0; row < this._dynamicForm.editForm.formRows.length; row++) {
      for (
        let col = 0;
        col < this._dynamicForm.editForm.formRows[row].formCol.length;
        col++
      ) {
        for (
          let element = 0;
          element <
          this._dynamicForm.editForm.formRows[row].formCol[col].colElement
            .length;
          element++
        ) {
          if (
            this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
              element
            ].placeholder === "emphy"
          ) {
            this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
              element
            ].placeholder = "";
          }
          if (
            this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
              element
            ].elementTitle === "emphy"
          ) {
            this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
              element
            ].elementTitle = "";
          }
          this.arrayElement.push(
            new colElement(
              this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
                element
              ].id,
              this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
                element
              ].col_id,
              this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
                element
              ].elementType,
              this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
                element
              ].elementTitle,
              this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
                element
              ].mandatory,
              this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
                element
              ].validation,
              this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
                element
              ].name,
              this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
                element
              ].placeholder,
              this._dynamicForm.editForm.formRows[row].formCol[col].colElement[
                element
              ].options
            )
          );
        }
        this.arrayCol.push(
          new formCol(
            this._dynamicForm.editForm.formRows[row].formCol[col].id,
            this._dynamicForm.editForm.formRows[row].formCol[col].row_id,
            this._dynamicForm.editForm.formRows[row].formCol[col].percentage,
            this.arrayElement
          )
        );
        this.arrayElement = [];
      }
      this.arrayRow.push(
        new formRow(
          this._dynamicForm.editForm.formRows[row].id,
          this._dynamicForm.editForm.formRows[row].form_id,
          this.arrayCol
        )
      );
      this.arrayCol = [];
    }
  }
  /**
   * When editing, go through the editForm service variable to load the current prototype of the form
   */
  getPrototipe() {
    this.formRowPrototipe = [];

    for (let row = 0; row < this._dynamicForm.editForm.formRows.length; row++) {
      this.form = new FormGroup({
        columns: new FormArray([]),
      });

      for (
        let col = 0;
        col < this._dynamicForm.editForm.formRows[row].formCol.length;
        col++
      ) {
        (<FormArray>this.form.controls["columns"]).push(
          new FormGroup({
            col: new FormControl(
              this._dynamicForm.editForm.formRows[row].formCol[col].percentage,
              []
            ),
            elements: new FormArray([]),
          })
        );
        for (
          let element = 0;
          element <
          this._dynamicForm.editForm.formRows[row].formCol[col].colElement
            .length;
          element++
        ) {
          //this._dynamicForm.formRow[this._dynamicForm.colX]
          (<FormArray>(
            this.form.controls.columns["controls"][col].controls["elements"]
          )).push(
            new FormGroup({
              element: new FormControl(
                this._dynamicForm.editForm.formRows[row].formCol[
                  col
                ].colElement[element]
              ),
            })
          );
        }
      }
      this.formRowPrototipe.push(this.form);
    }
  }

  /**
   * Toggles the visibility of public title and instructions
   */
  toggleCheck() {
    if (!this.publicTitleCheck) {
      this.publicTitle = "";
      this._dynamicForm.publicTitle = "";
    }
    if (!this.instructionsCheck) {
      this.instructions = "";
      this._dynamicForm.instructions = "";
    }
  }

  /**
   * Toggles the visibility of public title
   */
  toggleTitle() {
    this.publicTitleCheck = !this.publicTitleCheck;
    this._dynamicForm.publicTitleCheck = this.publicTitleCheck;

    // if (!this.publicTitleCheck) {
    //   this.publicTitle = "";
    //   this._dynamicForm.publicTitle = "";
    // }
  }

  /**
   * Toggles the visibility of instructions
   */
  toggleInstructions() {
    this.instructionsCheck = !this.instructionsCheck;
    this._dynamicForm.instructionsCheck = this.instructionsCheck;

    // if (!this.instructionsCheck) {
    //   this.instructions = "";
    //   this._dynamicForm.instructions = "";
    // }
  }

  /**
   * Clears the logo file
   */
  clearLogo() {
    this.logoBase64 = "";
    this._dynamicForm.logoFile = null;
    this._dynamicForm.logoBase64 = null;
    this._dynamicForm.editForm.logo_url = null;
    (<HTMLInputElement>document.getElementById("letterhead")).value = "";
  }

  /**
   * Sets validations message for max length of title
   */
  validarName() {
    if (this.publicTitle.length === 100) {
      this.lengthError = "Max length 100 characters";
    } else {
      this.lengthError = null;
    }
  }

  /**
   * Handles the dropped or selected files for the logo
   */
  public dropped(files: NgxFileDropEntry[]) {
    if (this.activeOnBoarding) {
      this.onBoardingStepsHeader[3].active = false;
      this.onBoardingStepsContent[0].active = true;
    }
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file

          this._dynamicForm.logoFile = file;

          let fileType = this._dynamicForm.logoFile.type.substr(0, 5);

          if (fileType === "image") {
            var reader = new FileReader();
            var result;

            fileEntry.file((file) => {
              reader.readAsDataURL(file);
              reader.onload = () => {
                result = reader.result;
                this.logoBase64 = result;
                this._dynamicForm.logoBase64 = result;
              };
            });
          } else {
            this._dynamicForm.logoFile = null;
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
  /**
   * Method to send the configuration of each step of the onBoarding, information that will be sent to the "onBoarding" component
   */
  configOnBoarding() {
    this.onBoardingStepsHeader = [
      {
        active: this.activeOnBoarding,
        title: "Set up your forms' header",
        color: "#003168",
        step: "startHeader",
        nextStep: "title",
        top: "-80px",
        right: "0px",
        arrow: "arrow-down",
        activateButton: true,
      },
      {
        active: false,
        title: "This is the public title that your user will see in your form",
        color: "#003168",
        step: "header1",
        nextStep: "instructions",
        top: "-21px",
        right: "-8px",
        arrow: "arrow-down",
        activateButton: true,
      },
      {
        active: false,
        title:
          "You may add instructions before your user start filling the form",
        color: "#003168",
        step: "header2",
        nextStep: "selectFile",
        top: "74px",
        right: "25px",
        arrow: "arrow-down",
        activateButton: true,
      },
      {
        active: false,
        title:
          "We know you have a really cool logo of your company, let's use it in your form",
        color: "#003168",
        step: "3",
        nextStep: "content",
        top: "139px",
        right: "0px",
        arrow: "arrow-down",
        activateButton: true,
      },
    ];
    this.onBoardingStepsContent = [
      {
        active: false,
        title: "Now it is time to create the content of your form!",
        color: "#003168",
        step: "0",
        nextStep: "addNewRowBtn",
        top: "-106px",
        right: "0px",
        arrow: "arrow-down",
        activateButton: true,
      },
      {
        active: false,
        title: "Simply add a row",
        color: "#003168",
        step: "1",
        nextStep: "modalColumns",
        top: "31px",
        right: "167px",
        arrow: "arrow-down",
        activateButton: true,
      },
      {
        active: false,
        title: "Set Up the columns that you need for this row",
        color: "#003168",
        step: "2",
        nextStep: "widthColumns",
        top: "-120px",
        right: "0px",
        arrow: "arrow-left",
        activateButton: true,
      },
      {
        active: false,
        title: "This is so cool that you can decide the width for each column!",
        color: "#003168",
        step: "3",
        nextStep: "toolbar",
        top: "25px",
        right: "0px",
        arrow: "arrow-left",
        activateButton: true,
      },
      {
        active: false,
        title: "Let me present you your toolbar",
        color: "#003168",
        step: "4",
        nextStep: "selectElement",
        top: "-26px",
        right: "390px",
        arrow: "arrow-down",
        activateButton: true,
      },
      {
        active: false,
        title:
          "Select the kind of element that you need and drag and drop it to the row",
        color: "#003168",
        step: "5",
        nextStep: "configElement",
        top: "135px",
        right: "-112px",
        arrow: "arrow-left",
        activateButton: false,
      },
      {
        active: false,
        title: "Setup the element clicking here",
        color: "#003168",
        step: "6",
        nextStep: "ok",
        top: "32px",
        right: "0px",
        arrow: "arrow-none",
        activateButton: true,
      },
      {
        active: false,
        title:
          "Click ok when you finish and continue working on your form content as much as you need it",
        color: "#003168",
        step: "7",
        nextStep: "end",
        top: "177px",
        right: "0px",
        arrow: "arrow-none",
        activateButton: true,
      },
    ];
  }
  /**
   *
   * @param action
   * Method to control the actions of the onBoarding in the header
   */
  onBoardingControlHeader(action) {
    document.getElementById(action.nextStep).focus();
    document.getElementById(action.nextStep).click();
    if (action.option == 1) {
      this.onBoardingStepsHeader[action.valor].active = false;
      if (action.valor + 1 != this.onBoardingStepsHeader.length) {
        this.onBoardingStepsHeader[action.valor + 1].active = true;
      }
      if (action.valor == 3) {
        this.onBoardingStepsContent[0].active = true;
      }
    }
    if (action.option == 2) {
      this.onBoardingStepsHeader[action.valor].active = false;
      this.user.onboarding = false;
      this.activeOnBoarding = false;

      let user_string = JSON.stringify(this.user);
      localStorage.setItem("current_user", user_string);
      this._dynamicForm
        .setOnBoarding(this.authService.getToken(), false)
        .subscribe(
          (response) => {},
          (error) => {}
        );
    }
  }
  /**
   * Method to control the actions of the onBoarding in the Content
   */
  onBoardingControlContent(action) {
    if (action.nextStep == "modalColumns") {
      document.getElementById("addNewRowBtn").click();
    }
    if (action.nextStep == "toolbar") {
      document.getElementById("createRow").click();
    }
    if (action.nextStep == "ok") {
      document.getElementById("exampleModal000down").click();
    }
    if (action.nextStep == "end") {
      this.user.onboarding = false;
      let user_string = JSON.stringify(this.user);
      localStorage.setItem("current_user", user_string);
    }

    if (action.option == 1) {
      this.onBoardingStepsContent[action.valor].active = false;
      if (action.valor + 1 != this.onBoardingStepsContent.length) {
        this.onBoardingStepsContent[action.valor + 1].active = true;
      }
    }
    if (action.option == 2) {
      this.onBoardingStepsContent[action.valor].active = false;
      this.user.onboarding = false;
      this.activeOnBoarding = false;
      let user_string = JSON.stringify(this.user);
      localStorage.setItem("current_user", user_string);
    }
  }

  /**
   * OnBoarding test method, serves to restart onBoarding from 0
   */
  reset() {
    for (let index = 0; index < this.onBoardingStepsHeader.length; index++) {
      if (index == 0) {
        this.onBoardingStepsHeader[index].active = true;
      } else {
        this.onBoardingStepsHeader[index].active = false;
      }
    }
    for (let index = 0; index < this.onBoardingStepsContent.length; index++) {
      this.onBoardingStepsContent[index].active = false;
    }
  }
}
