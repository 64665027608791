import { Component, Output, EventEmitter } from "@angular/core";
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";

@Component({
  selector: "ngbd-datepicker-range-popup",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
})
export class NgbdDatepickerRangePopup {
  @Output() params: EventEmitter<any> = new EventEmitter();
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  _fromDate: any;
  _toDate: any;
  maxDate: any;

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private datePipe: DatePipe
  ) {
    this.toDate = calendar.getToday();
    this.fromDate = calendar.getNext(calendar.getToday(), "d", -10);

    this._fromDate =
      this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day;
    this._toDate =
      this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day;

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    this.maxDate = { year: yyyy, month: mm, day: dd };
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this._fromDate =
        this.fromDate.year +
        "-" +
        this.fromDate.month +
        "-" +
        this.fromDate.day;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
      this._toDate =
        this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day;
    } else {
      this.toDate = null;
      this._toDate = null;
      this.fromDate = date;
      this._fromDate =
        this.fromDate.year +
        "-" +
        this.fromDate.month +
        "-" +
        this.fromDate.day;
    }

    if (this._fromDate && this._toDate) {
      const date1 = new Date(this._fromDate);
      const date2 = new Date(this._toDate);

      this.params.emit({
        inicio: this.datePipe.transform(this._fromDate, "dd/MM/yyyy"),
        fin: this.datePipe.transform(this._toDate, "dd/MM/yyyy"),
        tipo_rango: this.getTypeRange(date1, date2),
      });
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  getTypeRange(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log(diffDays + 1 + " days");

    if (diffDays + 1 <= 7) {
      return 3;
    } else if (diffDays + 1 > 7 && diffDays + 1 <= 31) {
      return 2;
    } else {
      return 1;
    }
  }

  formatDate() {}
}
